.nv-bgc-famorange {
  background-color: #123;
}

.nv-bgc-famlightorange {
  background-color: #123;
}

.nv-bgc-famborderlightgrey {
  background-color: #dbdbdb;
}

.nv-bgc-fambackgroundlightgrey {
  background-color: #f5f5f5;
}

.nv-bgc-famtextgrey {
  background-color: #484848;
}

.nv-bgc-famtextdark {
  background-color: #0d0d0d;
}

.nv-bgc-famdarkfooterstripe {
  background-color: #313131;
}

.nv-bgc-fammildgrey {
  background-color: #888888;
}

.nv-text-famorange {
  color: #123;
}
.nv-text-famorange a {
  color: #123;
}

.nv-text-famlightorange {
  color: #123;
}
.nv-text-famlightorange a {
  color: #123;
}

.nv-text-famborderlightgrey {
  color: #dbdbdb;
}
.nv-text-famborderlightgrey a {
  color: #dbdbdb;
}

.nv-text-fambackgroundlightgrey {
  color: #f5f5f5;
}
.nv-text-fambackgroundlightgrey a {
  color: #f5f5f5;
}

.nv-text-famtextgrey {
  color: #484848;
}
.nv-text-famtextgrey a {
  color: #484848;
}

.nv-text-famtextdark {
  color: #0d0d0d;
}
.nv-text-famtextdark a {
  color: #0d0d0d;
}

.nv-text-famdarkfooterstripe {
  color: #313131;
}
.nv-text-famdarkfooterstripe a {
  color: #313131;
}

.nv-text-fammildgrey {
  color: #888888;
}
.nv-text-fammildgrey a {
  color: #888888;
}

.nv-text-white {
  color: white;
}
.nv-text-white a {
  color: white;
}

.nv-bgc-famorange {
  color: #fff;
}

.nv-bgc-famlightorange {
  color: #fff;
}

.nv-bgc-white {
  background-color: white;
}

.nv-dropdown-menu {
  border: 1px solid #123;
  background-color: #f5f5f5;
}

.nv-dropdown-toggle::after {
  background-image: url("../images/icons/arrows/accordion-down.png");
}

.nv-mixed-dropdown-form .no-gutters > .btn {
  border-color: #123;
  border-bottom: 1px solid #123;
  border-top: 1px solid #123;
  color: #fff;
}
.nv-mixed-dropdown-form .no-gutters > .btn:first-child {
  border-left: 1px solid #123;
}
.nv-mixed-dropdown-form .no-gutters > .btn:last-child {
  border-right: 1px solid #123;
}
.nv-mixed-dropdown-form .no-gutters > .btn.nv-dropdown-toggle {
  background-color: #f5f5f5;
  color: #484848;
}

.nv-accordion .nv-accordion-item {
  border-top: 1px solid #dbdbdb;
}
.nv-accordion .nv-accordion-item .nv-accordion-head {
  position: relative;
  padding-right: 38px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.nv-accordion .nv-accordion-item .nv-accordion-head h1, .nv-accordion .nv-accordion-item .nv-accordion-head h2, .nv-accordion .nv-accordion-item .nv-accordion-head h3, .nv-accordion .nv-accordion-item .nv-accordion-head h4, .nv-accordion .nv-accordion-item .nv-accordion-head h5, .nv-accordion .nv-accordion-item .nv-accordion-head h6 {
  margin-bottom: 0;
}
.nv-accordion .nv-accordion-item .nv-accordion-head span.nv-arrow {
  background-image: url("../images/icons/arrows/accordion-down.png");
}
.nv-accordion .nv-accordion-item:last-child {
  border-bottom: 1px solid #dbdbdb;
}

.nv-triangle-right:after {
  background-color: inherit;
}

@media all and (max-width: 767px) {
  .nv-mixed-dropdown-form .no-gutters > .btn {
    border-color: #123;
    border-bottom: 1px solid #123;
    border-top: 1px solid #123;
    color: #fff;
  }
  .nv-mixed-dropdown-form .no-gutters > .btn:first-child {
    border-left: 1px solid #123;
    border-right: 1px solid #123;
  }
  .nv-mixed-dropdown-form .no-gutters > .btn:last-child {
    border-right: 1px solid #123;
  }
}
.owl-dots .owl-dot {
  background-color: #dbdbdb;
}
.owl-dots .owl-dot.active {
  background-color: #123;
}

.owl-nav .owl-prev, .owl-nav .owl-next {
  background-image: url("../images/icons/arrows/slider-left.png");
}

/* graceful degradation for ie8 */
input[type='checkbox'],
input[type='radio'] {
  background: transparent;
}

input[type='checkbox']:checked,
input[type='checkbox']:not(:checked),
input[type='radio']:checked,
input[type='radio']:not(:checked) {
  background: transparent;
}

input[type='checkbox']:checked + label::before,
input[type='checkbox']:not(:checked) + label::before,
input[type='radio']:checked + label::before,
input[type='radio']:not(:checked) + label::before {
  border: 1px solid #dbdbdb;
  background: white;
}

input[type='checkbox']:hover + label::before,
input[type='radio']:hover + label::before {
  background: #ddd;
}

input[type='checkbox']:checked + label::before,
input[type='radio']:checked + label::before {
  background-color: #123;
  background-image: url("../images/icons/check-white.png");
  border: 1px solid #123;
}

body {
  font-size: 16px;
  color: #484848;
}

h1 {
  margin-bottom: 20px;
}

h2 {
  color: #123;
}

a {
  color: #484848;
}
a:focus, a:hover {
  color: #484848;
}

hr {
  background-color: #dbdbdb;
}

.btn.btn-primary {
  background-color: #123;
  border-color: #123;
}
.btn.btn-primary:hover, .btn.btn-primary:active {
  background-color: #060b11;
  border-color: #123;
}

.nv-border-default, .btn, .nv-border-orange {
  border: 1px solid #dbdbdb;
}

.nv-border-orange {
  border-color: #123;
}

header {
  background-color: rgba(255, 255, 255, 0);
}
header .nv-nav-box .nv-nav li a {
  color: #fff;
}
header.scroll {
  background-color: white;
}
header.scroll .nv-nav li a {
  color: #0d0d0d;
}
header.scroll .nv-mobile-nav .nv-nav-stripe {
  background-color: #0d0d0d;
}

@media all and (max-width: 991px) {
  header .nv-nav-box ul.nv-nav {
    background-color: #fff;
  }
  header .nv-nav-box ul.nv-nav li a {
    color: #0d0d0d;
  }
}
header .nv-mobile-nav > div .nv-nav-stripe {
  background-color: #fff;
}

footer {
  color: #0d0d0d;
  background-color: #f5f5f5;
}
footer a {
  color: #0d0d0d;
}

.nv-banner .nv-accordion {
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}
.nv-banner .nv-accordion .nv-accordion-item .nv-accordion-content {
  border-top: 1px solid #dbdbdb;
}

.nv-place-teaser-container {
  background-color: #fff;
  color: #484848;
}

.nv-detail-breadcrumb a {
  color: #123;
}

.nv-detail-equipment-list li:before {
  background-image: url("../images/icons/check-orange.png");
}

.nv-search-sidebar .nv-accordion .nv-accordion-item .nv-accordion-content {
  border-top: 1px solid #dbdbdb;
}

.nv-rating {
  border-bottom: 1px solid #dbdbdb;
}

.progress {
  background-color: #dbdbdb;
}
.progress .progress-bar {
  background-color: #123;
}

.nv-pagination .nv-pagination-item {
  background-color: #fbfbfb;
}
.nv-pagination .nv-pagination-item.nv-next, .nv-pagination .nv-pagination-item.nv-prev {
  background-color: #f5f5f5;
}
.nv-pagination .nv-pagination-item.nv-next a, .nv-pagination .nv-pagination-item.nv-prev a {
  color: #123;
}
.nv-pagination .nv-pagination-item.active, .nv-pagination .nv-pagination-item:hover {
  background-color: #123;
}
.nv-pagination .nv-pagination-item.active a, .nv-pagination .nv-pagination-item:hover a {
  color: #fff;
}
